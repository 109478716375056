<template>
  <LazyClientOnly>
    <Popper
      v-if="popperInited"
      hover
      z-index="19"
      arrow
      :placement="props.placement"
      class="popper-tooltip"
    >
      <div class="cursor-pointer">
        <slot />
      </div>

      <template #content>
        <div
          role="tooltip"
          :class="props.noWrap ? 'whitespace-nowrap' : null"
          class="rounded-lg px-3 py-2 text-sm font-medium text-white"
        >
          {{ content }}
        </div>
      </template>
    </Popper>
    <div
      v-else
      class="inline-block cursor-pointer"
      @mouseover="initPopper"
    >
      <slot />
    </div>
  </LazyClientOnly>
</template>

<script lang="ts" setup>
import Popper from 'vue3-popper'

export type TooltipPlacement = 'top' | 'bottom' | 'right' | 'left' | 'auto'

interface Props {
  placement?: TooltipPlacement
  content: string
  noWrap?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  placement: 'top',
  noWrap: true,
})

const popperInited = ref(false)

const initPopper = () => {
  if (!popperInited.value) {
    popperInited.value = true
  }
}
</script>
